import { Button, H3 } from '@mehilainen/design-system'
import React, { useState } from 'react'

import { EmailField } from './fields/EmailField'
import styled from 'styled-components'

type Props = {
  handleRequestPasswordReset: (email: string) => void
  heading?: string
  text?: string
  loading: boolean
}

export default ({
  handleRequestPasswordReset,
  heading,
  text,
  loading,
}: Props) => {
  const [email, setEmail] = useState<string>('')
  const disabled = !email

  return (
    <RequestPasswordResetForm>
      {heading && <H3>{heading}</H3>}
      {text && <p>{text}</p>}
      <EmailField email={email} setEmail={setEmail} />
      <Buttons>
        <Button
          disabled={disabled}
          large
          loading={loading}
          onClick={e => {
            e.preventDefault()
            handleRequestPasswordReset(email)
          }}
        >
          Lähetä
        </Button>
      </Buttons>
    </RequestPasswordResetForm>
  )
}

const RequestPasswordResetForm = styled.form`
  display: grid;
  grid-template-columns: minmax(min(90vw, 388px), min(90vw, 582px));
  justify-content: center;
  gap: 16px 0;
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`
